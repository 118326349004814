import * as React from "react";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import { Grid } from "@mui/material";

export default function FranchiseApplicationForm() {
  const [formData, setFormData] = React.useState({
    companyName: "",
    contactPerson: "",
    email: "",
    phone: "",
    additionalInformation: "",
  });

  const handleChange = (e: any) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = (e: any) => {
    e.preventDefault();
    // Add logic to submit the form data, e.g., send it to a server
    console.log("Form data submitted:", formData);
  };

  return (
    <>
      <Grid container display={"flex"} justifyContent={"center"} padding={3}>
        <Grid item xs={5}>
          <form onSubmit={handleSubmit}>
            <TextField
              label="公司名称"
              variant="outlined"
              fullWidth
              margin="normal"
              name="companyName"
              value={formData.companyName}
              onChange={handleChange}
            />
            <TextField
              label="联系人"
              variant="outlined"
              fullWidth
              margin="normal"
              name="contactPerson"
              value={formData.contactPerson}
              onChange={handleChange}
            />
            <TextField
              label="邮箱"
              variant="outlined"
              fullWidth
              margin="normal"
              name="email"
              type="email"
              value={formData.email}
              onChange={handleChange}
            />
            <TextField
              label="电话"
              variant="outlined"
              fullWidth
              margin="normal"
              name="phone"
              type="tel"
              value={formData.phone}
              onChange={handleChange}
            />
            <TextField
              label="额外信息"
              variant="outlined"
              fullWidth
              multiline
              rows={4}
              margin="normal"
              name="additionalInformation"
              value={formData.additionalInformation}
              onChange={handleChange}
            />
            <Box mt={2}>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                fullWidth
              >
                提交申请
              </Button>
            </Box>
          </form>
        </Grid>
      </Grid>
    </>
  );
}
