import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { StyledTableCell, StyledTableRow, createData } from "../tableStyle";

const HenanStation = () => {
  const rows = [
    createData(
      "郑州",
      "经开区二十二大街与浔江东路交叉口东800米路南",
      "0371-60107200"
    ),
    createData(
      "华南城",
      "华南城大道（地铁桥）与求实路交叉口向西200米路南",
      "0371-60107200"
    ),
    createData(
      "安阳",
      "文峰区彰德路南段小营工业区向东100米路南",
      "0372-2131055"
    ),
    createData(
      "滑县",
      "老城濮上路与南环路北20米路东（龙碑东北角）",
      "18537272405"
    ),
    createData(
      "濮阳",
      "解放北路与北环路交叉口向北1公里界牌桥头",
      "0393-5388006"
    ),
    createData("华龙区", "京开道与老濮台交叉口向东300米路南", "15839331122"),
    createData("鹤壁", "淇滨区107国道新家园钢材市场院内", "0392-3337991"),
    createData("新乡", "卫滨区南环路西朱召市场对面豫北驾校院内", "17530103471"),
    createData("焦作", "中站区丰收路西段金顿钢材市场西门口", "0391-3213386"),
    createData(
      "台前",
      "西环路与台吴公路红绿灯向北500米（荆石）路东",
      "13683937190"
    ),
    createData("清丰", "清丰亭正西红绿灯向北50米路西", "19937151809"),
    createData("内黄", "南环路与枣乡大道交叉口东南角", "18317700775"),
    createData("南乐", "南乐老东环建材市场园内", "18639332186"),
    createData(
      "范县",
      "老城南环路中段（樱桃园车站向西300米路南）",
      "15069555905"
    ),
    createData("长垣", "浦北吕阵大队小殷庄路北23号", "17527031116"),
    createData("永城", "永城六路环岛盛华物流园院内5号仓库", "0370-50133999"),
    createData(
      "许昌",
      "建安区南环路与许州路交汇北100米路东森瑞物流园",
      "0374-5767111"
    ),
    createData("长葛", "东转盘向东300米奥斯帆超市后100米路东", "0374-6266009"),
    createData(
      "漯河",
      "源汇区泰山路与南环路交叉口向西二十米路南",
      "0395-3333327"
    ),
    createData(
      "平顶山",
      "卫东区东环路与北环路交叉口西600米路南",
      "0375-6151618"
    ),
    createData("信阳", "平桥区二十六大街国际商城东门正对面", "0376-3285888"),
    createData("潢川", "方店新村一期向西100米", "0376-8168057"),
    createData(
      "固始",
      "204省道与廖北路交叉口金马集团院内玻璃房",
      "0376-8882228"
    ),
    createData(
      "驻马店",
      "驿城区中原大道与东开路交叉口往东200米路北",
      "0396-2370299"
    ),
    createData(
      "南阳",
      "卧龙区光武路与北京路交汇东200米路北嘉华物流园",
      "0377-60397186"
    ),
    createData("周口", "川汇区华耀城太清路零担货运院内兴华物流", "13137440353"),
    createData(
      "太康",
      "少康路与南二环交叉口往西20米路北永康物流园内",
      "13523686758"
    ),
    createData("郸城", "久鸿农贸市场对面向北100米路西", "0394-7817086"),
    createData("鹿邑", "鹿邑县涡北镇产业聚集区鑫晶机械厂院内", "13353859565"),
    createData(
      "商丘",
      "梁园区平原路与陇海路交叉口向东1000路北兴华物流",
      "0370-6083331"
    ),
    createData(
      "柘城",
      "柘城县浦东路296号（梁庄中学向东500米路北）",
      "0370-7296777"
    ),
    createData(
      "洛阳",
      "关林长夏门街与展览路交汇南200米兴华物流园",
      "13373791010"
    ),
    createData("灵宝", "振兴路南田村村口，熙龙湾小区对面", "13839889856"),
    createData(
      "三门峡",
      "铝厂转盘向东800m银昌路路北斜桥停车场院内",
      "13323663803"
    ),
    createData("开封", "鼓楼区金明大道南段矛盾集团后院", "0371-22332330"),
  ];

  return (
    <TableContainer component={Paper}>
      <Table aria-label="shandong">
        <TableHead>
          <TableRow>
            <StyledTableCell>河南专线卸货网点</StyledTableCell>
            <StyledTableCell>地址</StyledTableCell>
            <StyledTableCell>电话</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row, index) => (
            <StyledTableRow key={index}>
              <StyledTableCell component="th" scope="row">
                {row.name}
              </StyledTableCell>
              <StyledTableCell>{row.address}</StyledTableCell>
              <StyledTableCell>
                <a href="tel:${row.phone}"> {row.phone} </a>
              </StyledTableCell>
            </StyledTableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default HenanStation;
