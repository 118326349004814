import * as React from "react";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { StyledTableCell, StyledTableRow, createData } from "../tableStyle";

const XizangStation = () => {
  const rows = [
    createData(
      "拉萨",
      "拉萨市城北交警大队背后蓝天物流园内胜中提货处",
      "18708003779"
    ),
    createData(
      "拉萨",
      "拉萨市城北交警大队背后蓝天物流园内胜中提货处",
      "18708003779"
    ),
  ];

  return (
    <TableContainer component={Paper}>
      <Table aria-label="shandong">
        <TableHead>
          <TableRow>
            <StyledTableCell>西藏卸货网点</StyledTableCell>
            <StyledTableCell>地址</StyledTableCell>
            <StyledTableCell>电话</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row, index) => (
            <StyledTableRow key={index}>
              <StyledTableCell component="th" scope="row">
                {row.name}
              </StyledTableCell>
              <StyledTableCell>{row.address}</StyledTableCell>
              <StyledTableCell>
                <a href="tel:${row.phone}"> {row.phone} </a>
              </StyledTableCell>
            </StyledTableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default XizangStation;
