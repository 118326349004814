import {
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  Grid,
} from "@mui/material";
import { StyledTableCell } from "../../stations/components/tableStyle";

const data = [
  {
    id: 1,
    province: "省份1",
    city: "城市1",
    region: "区域1",
    note: "备注1",
    contact: "联系人1",
    phone: "1234567890",
  },
  {
    id: 2,
    province: "省份2",
    city: "城市2",
    region: "区域2",
    note: "备注2",
    contact: "联系人2",
    phone: "0987654321",
  },
  // 添加更多数据
];

const JoinAdvantagesTable = () => {
  return (
    <>
      <Grid container marginLeft={9} padding={3} height={800}>
        <Grid item xs={12} lg={11}>
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <StyledTableCell>省份</StyledTableCell>
                  <StyledTableCell>城市</StyledTableCell>
                  <StyledTableCell>区域</StyledTableCell>
                  <StyledTableCell>区域备注</StyledTableCell>
                  <StyledTableCell>联系人</StyledTableCell>
                  <StyledTableCell>联系电话</StyledTableCell>
                  {/* <StyledTableCell>操作</StyledTableCell> */}
                </TableRow>
              </TableHead>
              <TableBody>
                {data.map((row) => (
                  <TableRow key={row.id}>
                    <StyledTableCell>{row.province}</StyledTableCell>
                    <StyledTableCell>{row.city}</StyledTableCell>
                    <StyledTableCell>{row.region}</StyledTableCell>
                    <StyledTableCell>{row.note}</StyledTableCell>
                    <StyledTableCell>{row.contact}</StyledTableCell>
                    <StyledTableCell>{row.phone}</StyledTableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>
    </>
  );
};

export default JoinAdvantagesTable;
