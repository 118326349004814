import { Step, StepLabel, Stepper, TextField } from "@mui/material";
import { useState } from "react";

import { LoadingButton } from "@mui/lab";
import { fetchWaybill } from "../services";

function parseAndMakePhoneClickable(note: string) {
  const phoneRegex = /(\d{11})/g;

  const parsedNote = note.replace(phoneRegex, '<a href="tel:$1">$1</a>');

  return (
    <div
      key={note}
      dangerouslySetInnerHTML={{
        __html: parsedNote,
      }}
    />
  );
}

const WaybillForm = () => {
  const [loading, setLoading] = useState(false);
  const [waybillNumber, setWaybillNumber] = useState("");
  const [dialogOpen, setDialogOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [steps, setSteps] = useState<Log[]>([]);
  const [showStep, setShowStep] = useState(false);

  const handleOrder = async () => {
    setLoading(true);
    await new Promise((resolve) => setTimeout(resolve, 1000));
    if (!waybillNumber) {
      setLoading(false);
      setShowStep(false);
      setErrorMessage("订单不能为空");
      return;
    }

    try {
      const response = await fetchWaybill(waybillNumber);
      if (response.success) {
        setShowStep(true);
        setErrorMessage("");
        setSteps(response.data.log);
        return;
      } else {
        setShowStep(false);
        setErrorMessage("运单号错误 请重新输入");
        return;
      }
    } catch (error) {
      setShowStep(false);
      setErrorMessage("网络异常");
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <div className="container-item">
        <div className="form-item">
          <TextField
            error={Boolean(errorMessage)}
            id="to"
            label="运单号"
            style={{ width: 200 }}
            multiline
            variant="standard"
            value={waybillNumber}
            onChange={(e) => setWaybillNumber(e.target.value)}
            helperText={errorMessage}
          />

          <LoadingButton
            onClick={handleOrder}
            loading={loading}
            color="primary"
            variant="outlined"
          >
            运单查询
          </LoadingButton>
        </div>

        <div className="form-detail">
          <div>
            {showStep && (
              <Stepper
                className="item-step"
                activeStep={steps.length}
                orientation="vertical"
              >
                {steps.map((step, index) => (
                  <Step key={index}>
                    <StepLabel>
                      <div>
                        <div>{step.actName}</div>
                        <div>{step.actTime}</div>
                        <div>{step.note.map(parseAndMakePhoneClickable)}</div>
                      </div>
                    </StepLabel>
                  </Step>
                ))}
              </Stepper>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default WaybillForm;
