import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { StyledTableCell, StyledTableRow, createData } from "../tableStyle";

const HebeiStation = () => {
  const rows = [
    createData(
      "石家庄",
      "长安区胜利北街聚合港物流园聚合7路17号",
      "15383812932"
    ),
    createData(
      "邯郸",
      "邯山区文杰物流园铝材市场中间十字路口左转",
      "0310-551-7330"
    ),
    createData("邢台", "桥东区北环森林公园路西白马物流园", "19103249876"),
    createData(
      "张家口",
      "桥西区老110国道许家庄村口西远锦物流园",
      "0313-5511551"
    ),
    createData("保定", "莲池区南二环钢材物流园内", "15100229096"),
    createData("大名", "215省道乐得石化北行200米路西胜大物流", "0310658158"),
    createData("故城", "故城县交警队红绿灯北行200米路东", "13483729476"),
    createData("枣强", "枣中路南行200米路东", "13483729476"),
    createData("辛集", "育红街转盘西行20米路北弘昌物流", "0311-85552345"),
    createData("衡水", "桃城区东明物流园东1区515号", "0318-2157778"),
  ];

  return (
    <TableContainer component={Paper}>
      <Table aria-label="shandong">
        <TableHead>
          <TableRow>
            <StyledTableCell>河北专线卸货网点</StyledTableCell>
            <StyledTableCell>地址</StyledTableCell>
            <StyledTableCell>电话</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row, index) => (
            <StyledTableRow key={index}>
              <StyledTableCell component="th" scope="row">
                {row.name}
              </StyledTableCell>
              <StyledTableCell>{row.address}</StyledTableCell>
              <StyledTableCell>
                <a href="tel:${row.phone}"> {row.phone} </a>
              </StyledTableCell>
            </StyledTableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default HebeiStation;
