import { Grid, Typography } from "@mui/material";

const AboutUs = () => {
  return (
    <>
      <Grid
        container
        display={"flex"}
        justifyContent={"center"}
        spacing={3}
        padding={5}
        height={500}
      >
        {/* 企业优势 */}
        <Grid item xs={10} lg={10}>
          <Typography
            variant="h4"
            sx={{ marginBottom: 2, textAlign: "center" }}
          >
            企业简介
          </Typography>

          <Typography marginTop={3}>
            山东兴华万通供应链集团有限公司诞生于物流之都——山东临沂
          </Typography>
          <Typography>
            是一家集公铁联运、整车零担、仓储配送、三方业务、增值产品、供应链管理等各项服务为一体的大型国家4A级综合物流企业。
            兴华万通以专业的团队、科学的管理体系、健全的网络为基础、以超前的服务理念、丰富的物流实践、全程可视化的信息服务、标准化的运作流程为基石，打造临沂至全国互联互通的物流网络，为企业提供一站式的数字化供应链服务。
          </Typography>
        </Grid>
      </Grid>
    </>
  );
};

export default AboutUs;
