import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { StyledTableCell, StyledTableRow, createData } from "../tableStyle";

const AnhuiStation = () => {
  const rows = [
    createData(
      "合肥",
      "瑶海区大泽路与鹿鸣山路交叉口启耀物流园",
      "0551-65410249"
    ),
    createData("明光", "明光市嘉山大道136号（体育馆对面）", "13965977323"),
    createData("芜湖", "弋江区瑞丰商贸城区加油站向北100米", "18715291787"),
    createData("铜陵", "铜官区鹊江路中润物流园内世民物流", "13905625095"),
    createData("毫州", "谯城区天运物流园院内7栋26号", "05585080979"),
    createData("蚌埠", "淮上区双墩物流园南排18-19号太平洋物流", "0552-4088369"),
    createData("阜阳", "颍泉区临沂商城物流园大鹏物流A2-14", "0558-2687899"),
    createData("临泉", "沈郢路华源医院南50米路东王艺喜物流院内", "18226311179"),
  ];

  return (
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            <StyledTableCell>安徽专线卸货网点</StyledTableCell>
            <StyledTableCell>地址</StyledTableCell>
            <StyledTableCell>电话</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row, index) => (
            <StyledTableRow key={index}>
              <StyledTableCell component="th" scope="row">
                {row.name}
              </StyledTableCell>
              <StyledTableCell>{row.address}</StyledTableCell>
              <StyledTableCell>
                <a href="tel:${row.phone}"> {row.phone} </a>
              </StyledTableCell>
            </StyledTableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default AnhuiStation;
