import React from "react";
import {
  Stepper,
  Step,
  StepLabel,
  Typography,
  Paper,
  Grid,
} from "@mui/material";

const steps = [
  "2020年9月 - 兴华万通成立",
  "2020年12月 - 第一轮业务并购",
  "2021年4月 - 项目招商启动",
  "2022年2月 - 智能物流系统投入使用",
  "2022年10月-12月 - 第二轮业务并购",
  "2023年8月 - 整合德众物流",
];

const StoryTimeline = () => {
  const [activeStep, setActiveStep] = React.useState(0);

  const handleStep = (step: number) => () => {
    setActiveStep(step);
  };

  return (
    <Grid
      container
      spacing={2}
      justifyContent="center"
      padding={5}
      height={500}
    >
      <Grid item xs={12} md={8}>
        {/* <Paper elevation={3} style={{ padding: "20px" }}> */}
        <Typography variant="h5" align="center" gutterBottom>
          我们的故事
        </Typography>
        <Stepper activeStep={activeStep} alternativeLabel>
          {steps.map((label, index) => (
            <Step key={label}>
              <StepLabel onClick={handleStep(index)}>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>
        {/* Optional: Display specific content for each step */}
        <div style={{ marginTop: "20px" }}>
          {activeStep === 0 && (
            <Typography variant="body1" align="center">
              2020年9月兴华万通正式成立，一站式临沂点发全国零担物流创网项目正式立项，
              智能物流运输管理系统投入研发。
            </Typography>
          )}
          {activeStep === 1 && (
            <Typography variant="body1" align="center">
              完成第一轮业务并购；并购兴华物流——河南全境、永盛物流——江苏全境，同时制定SOP（企业标准化管理）项目规则。
            </Typography>
          )}
          {activeStep === 2 && (
            <Typography variant="body1" align="center">
              项目招商正式启动，第三代品牌升级正式立项。
            </Typography>
          )}
          {activeStep === 2 && (
            <Typography variant="body1" align="center">
              自主研发的智能物流运输管理系统——“飞翼”系统
              投入使用;第三代品牌升级换代完成。
            </Typography>
          )}
          {activeStep === 3 && (
            <Typography variant="body1" align="center">
              完成第二轮业务并购，并购鲁疆物流——西北五省，同时成立山东保时通物流，整合山东全境。
            </Typography>
          )}
          {activeStep === 4 && (
            <Typography variant="body1" align="center">
              整合德众物流（山东）有限公司，为企业提供一站式供应链解决方案。
            </Typography>
          )}
        </div>
        {/* </Paper> */}
      </Grid>
    </Grid>
  );
};

export default StoryTimeline;
