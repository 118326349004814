import Home from "./pages/home";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import "./App.css";
import Products from "./pages/products";
import Shipping from "./pages/shipping";
import About from "./pages/about";
import Dev from "./pages/dsp";
import Feedback from "./pages/feedback";
import Header from "./pages/header";
import { useEffect, useState } from "react";
import Honor from "./pages/honor";
import Investment from "./pages/investment";
import Culture from "./pages/culture";
import Footer from "./pages/footer/footer";
import Stations from "./pages/stations";
import TopNav from "./pages/header_old/components/TopNav";
import HomeNav from "./pages/header_old/components/HomeNav";
import Join from "./pages/joinus";

const App = () => {
  const [viewportWidth, setViewportWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setViewportWidth(window.innerWidth);
      // 处理其他与宽度变化有关的逻辑
    };

    // 监听窗口大小变化
    window.addEventListener("resize", handleResize);

    // 初始化时获取一次视窗宽度
    handleResize();

    // 清理函数，在组件销毁时取消监听
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <Router>
      <Header />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/shipping" element={<Shipping />} />
        <Route path="/products" element={<Products />} />
        <Route path="/about" element={<About />} />
        <Route path="/stations" element={<Stations />} />
        <Route path="/dsp" element={<Dev />} />
        <Route path="/join-us" element={<Join />} />
        <Route path="/honor" element={<Honor />} />
        <Route path="/feedback" element={<Feedback />} />
        <Route path="/investment" element={<Investment />} />
        <Route path="/culture" element={<Culture />} />
      </Routes>
      <Footer />
    </Router>
  );
};

export default App;
