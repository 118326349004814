import { useState } from "react";
import "./index.css";

const Header = () => {
  const [isMenuVisible, setMenuVisibility] = useState(false);
  const handleClick = () => {
    setMenuVisibility(!isMenuVisible);
  };

  const currentPath = window.location.pathname;

  return (
    <>
      <header id="header-wrapper">
        <div className="left-wrapper">
          <a href="/" className="brand-logo">
            <img src="/logo192.png" alt="logo" />
          </a>
        </div>
        <div className="right-wrapper">
          <ul id="nav-list">
            <li className={`nav-item  ${currentPath === "/" ? "active" : ""}`}>
              <a href="/">
                <p>首页</p>
              </a>
            </li>
            <li
              className={`nav-item  ${
                currentPath === "/products" ? "active" : ""
              }`}
            >
              <a href="/products">
                <p>产品介绍</p>
              </a>
            </li>
            <li
              className={`nav-item  ${
                currentPath === "/shipping" ? "active" : ""
              }`}
            >
              <a href="/shipping">
                <p>寄件查询</p>
              </a>
            </li>
            <li
              className={`nav-item  ${
                currentPath === "/stations" ? "active" : ""
              }`}
            >
              <a href="/stations">
                <p>网点查询</p>
              </a>
            </li>
            <li
              className={`nav-item  ${
                currentPath === "/investment" ? "active" : ""
              }`}
            >
              <a href="/investment">
                <p>招商加盟</p>
              </a>
            </li>
            <li id="about" className="nav-item">
              <p>关于我们</p>
              <div>
                <i className="fa-solid fa-chevron-down fa-lg icon-arrow-down"></i>
                <div id="solutions-wrapper" className="nav-item-slider">
                  <ul className="nav-item-slider-normal-content">
                    <li className="item">
                      <a href="/about">
                        <span>公司简介</span>
                      </a>
                    </li>
                    <li className="item">
                      <a href="/about?tab=1">
                        <span>发展历程</span>
                      </a>
                    </li>
                    <li className="item">
                      <a href="/about?tab=2">
                        <span>企业文化</span>
                      </a>
                    </li>
                    <li className="item">
                      <a href="/about?tab=3">
                        <span>企业荣誉</span>
                      </a>
                    </li>
                    <li className="item">
                      <a href="/join-us">
                        <span>人才招聘</span>
                      </a>
                    </li>
                    <li className="item">
                      <a href="/feedback">
                        <span>投诉建议</span>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </li>
          </ul>
          <div className="jump-btn-wrap">
            <span className="jump-btn">
              <a href="tel:4000009777">联系我们</a>
            </span>
          </div>
        </div>
      </header>

      <div className="header-wrapper header-block">
        <a href="/" className="logo-warp">
          <img src="/logo192.png" className="icon-arrow-down" />
        </a>

        <div className="nav-contact">
          <span
            style={{ display: isMenuVisible ? "none" : "block" }}
            className="hover-item jump-btn"
          >
            <a href="tel:4000009777">联系我们</a>
          </span>
          <span id="showMobileHeaderBt" onClick={handleClick}>
            {!isMenuVisible ? (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                height="32"
                width="28"
                viewBox="0 0 448 512"
              >
                <path d="M0 96C0 78.3 14.3 64 32 64H416c17.7 0 32 14.3 32 32s-14.3 32-32 32H32C14.3 128 0 113.7 0 96zM0 256c0-17.7 14.3-32 32-32H416c17.7 0 32 14.3 32 32s-14.3 32-32 32H32c-17.7 0-32-14.3-32-32zM448 416c0 17.7-14.3 32-32 32H32c-17.7 0-32-14.3-32-32s14.3-32 32-32H416c17.7 0 32 14.3 32 32z" />
              </svg>
            ) : (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                height="32"
                width="28"
                viewBox="0 0 384 512"
              >
                <path d="M342.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 210.7 86.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L146.7 256 41.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L192 301.3 297.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L237.3 256 342.6 150.6z" />
              </svg>
            )}
          </span>
        </div>
      </div>
      <div
        className="header-main justify-center"
        id="mobileHeader"
        style={{ display: isMenuVisible ? "block" : "none" }}
      >
        <div className="header-main header-sub">
          <div>
            <div className="flex-col">
              <div className="navItem">
                <h3 className="cursor-pointer text-black md:text-8xl">首页</h3>
              </div>
            </div>
            <div className="flex-col">
              <div className="navItem">
                <h3 className="cursor-pointer text-black md:text-8xl">
                  产品介绍
                </h3>
              </div>
            </div>
            <div className="flex-col">
              <div className="navItem">
                <h3 className="cursor-pointer text-black md:text-8xl">
                  寄件查询
                </h3>
              </div>
            </div>
            <div className="flex-col">
              <div className="navItem">
                <a href="/stations">
                  <h3 className="cursor-pointer text-black md:text-8xl">
                    网点查询
                  </h3>
                </a>
              </div>
            </div>
            <div className="flex-col">
              <div className="navItem">
                <a href="/news-room">
                  <h3 className="cursor-pointer text-black md:text-8xl">
                    招商加盟
                  </h3>
                </a>
              </div>
            </div>
            <div className="flex-col">
              <div className="navItem">
                <a href="/news-room">
                  <h3 className="cursor-pointer text-black md:text-8xl">
                    关于我们
                  </h3>
                </a>
              </div>
            </div>
            <div className="flex-col">
              <div className="navItem">
                <h3 className="cursor-pointer text-black md:text-8xl">
                  <a href="tel:4000009777">联系我们</a>
                </h3>
              </div>
            </div>
          </div>
          <div className="flex-col w-icon">
            <ul className="flex-col">
              <li className="flex-col-contact">
                <a href="tel:4000009777">
                  <h5 className="text-black md:hidden">联系我们</h5>
                </a>
              </li>
              <li className="mt-5 mb-10 md:my-0">
                <a href="https://www.facebook.com/nativex/">
                  <img
                    src="data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4KPCEtLSBHZW5lcmF0b3I6IEFkb2JlIElsbHVzdHJhdG9yIDI1LjEuMCwgU1ZHIEV4cG9ydCBQbHVnLUluIC4gU1ZHIFZlcnNpb246IDYuMDAgQnVpbGQgMCkgIC0tPgo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9IuWbvuWxgl8xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB4PSIwcHgiIHk9IjBweCIKCSB2aWV3Qm94PSIwIDAgMzYgMzYiIHN0eWxlPSJlbmFibGUtYmFja2dyb3VuZDpuZXcgMCAwIDM2IDM2OyIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSI+CjxnIGlkPSJMYXllcl8yIj4KCTxnIGlkPSJMYXllcl8xLTIiPgoJCTxwYXRoIGQ9Ik0wLDB2MzZoMTkuMlYyMi4xaC00Ljd2LTUuNGg0Ljd2LTRjMC00LjcsMi44LTcuMiw3LTcuMmMxLjQsMCwyLjgsMC4xLDQuMiwwLjJ2NC45aC0yLjljLTIuMiwwLTIuNywxLjEtMi43LDIuN3YzLjVoNS40CgkJCWwtMC43LDUuNGgtNC43VjM2SDM2VjBIMHoiLz4KCTwvZz4KPC9nPgo8L3N2Zz4K"
                    className="unhover contact-icon"
                  />
                  <img
                    src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAzNiAzNiI+PGRlZnM+PHN0eWxlPi5jbHMtMXtmaWxsOiMyNjRmZmY7fTwvc3R5bGU+PC9kZWZzPjx0aXRsZT5Bc3NldCAzPC90aXRsZT48ZyBpZD0iTGF5ZXJfMiIgZGF0YS1uYW1lPSJMYXllciAyIj48ZyBpZD0iTGF5ZXJfMS0yIiBkYXRhLW5hbWU9IkxheWVyIDEiPjxwYXRoIGNsYXNzPSJjbHMtMSIgZD0iTTAsMFYzNkgxOS4yM1YyMi4wNkgxNC41NFYxNi42M2g0LjY5di00YzAtNC42NSwyLjg0LTcuMTgsNy03LjE4YTQwLjc2LDQwLjc2LDAsMCwxLDQuMTkuMjF2NC44NkgyNy41M2MtMi4yNSwwLTIuNjksMS4wNy0yLjY5LDIuNjV2My40N2g1LjM4bC0uNyw1LjQzSDI0Ljg0VjM2SDM2VjBaIi8+PC9nPjwvZz48L3N2Zz4="
                    className="hover contact-icon"
                  />
                </a>
                <a href="https://twitter.com/nativeX?lang=en">
                  <img
                    src="data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4KPCEtLSBHZW5lcmF0b3I6IEFkb2JlIElsbHVzdHJhdG9yIDIzLjAuMCwgU1ZHIEV4cG9ydCBQbHVnLUluIC4gU1ZHIFZlcnNpb246IDYuMDAgQnVpbGQgMCkgIC0tPgo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9IuWbvuWxgl8xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB4PSIwcHgiIHk9IjBweCIKCSB2aWV3Qm94PSIwIDAgMzYgMzYiIHN0eWxlPSJlbmFibGUtYmFja2dyb3VuZDpuZXcgMCAwIDM2IDM2OyIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSI+Cjx0aXRsZT5Bc3NldCAyPC90aXRsZT4KPGcgaWQ9IkxheWVyXzIiPgoJPGcgaWQ9IkxheWVyXzEtMiI+CgkJPHBhdGggZD0iTTAsMHYzNmgzNlYwSDB6IE0yOCwxMi45YzAsMC4yLDAsMC40LDAsMC43YzAsOC4xLTYuNSwxNC42LTE0LjYsMTQuNmMtMi44LDAtNS42LTAuOC03LjktMi4zYzAuNCwwLDAuOCwwLjEsMS4yLDAuMQoJCQljMi4zLDAsNC42LTAuOCw2LjQtMi4yYy0yLjIsMC00LjEtMS41LTQuOC0zLjZjMC4zLDAuMSwwLjcsMC4xLDEsMC4xYzAuNSwwLDAuOS0wLjEsMS40LTAuMmMtMi40LTAuNS00LjEtMi42LTQuMS01VjE1CgkJCWMwLjcsMC40LDEuNSwwLjYsMi4zLDAuNmMtMi4zLTEuNS0zLTQuNS0xLjYtNi45YzIuNiwzLjIsNi41LDUuMiwxMC42LDUuNGMtMC4xLTAuNC0wLjEtMC43LTAuMS0xLjFjMC0yLjgsMi4zLTUuMSw1LjEtNS4xCgkJCWMxLjQsMCwyLjgsMC42LDMuOCwxLjZjMS4yLTAuMiwyLjMtMC43LDMuMy0xLjNjLTAuNCwxLjItMS4yLDIuMi0yLjMsMi44YzEtMC4xLDItMC40LDMtMC44QzI5LjksMTEuMiwyOSwxMi4yLDI4LDEyLjl6Ii8+Cgk8L2c+CjwvZz4KPC9zdmc+Cg=="
                    className="unhover contact-icon"
                  />
                  <img
                    src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAzNiAzNiI+PGRlZnM+PHN0eWxlPi5jbHMtMXtmaWxsOiMyNjRmZmY7fTwvc3R5bGU+PC9kZWZzPjx0aXRsZT5Bc3NldCAyPC90aXRsZT48ZyBpZD0iTGF5ZXJfMiIgZGF0YS1uYW1lPSJMYXllciAyIj48ZyBpZD0iTGF5ZXJfMS0yIiBkYXRhLW5hbWU9IkxheWVyIDEiPjxwYXRoIGNsYXNzPSJjbHMtMSIgZD0iTTAsMFYzNkgzNlYwWk0yOCwxMi44OWMwLC4yMiwwLC40NCwwLC42N0ExNC42MiwxNC42MiwwLDAsMSw1LjQ3LDI1Ljg3YTEwLjE0LDEwLjE0LDAsMCwwLDEuMjMuMDcsMTAuMjksMTAuMjksMCwwLDAsNi4zOC0yLjIsNS4xNSw1LjE1LDAsMCwxLTQuOC0zLjU3LDUuMjMsNS4yMywwLDAsMCwxLC4xLDUsNSwwLDAsMCwxLjM1LS4xOSw1LjEzLDUuMTMsMCwwLDEtNC4xMi01VjE1YTUsNSwwLDAsMCwyLjMzLjY0QTUuMTMsNS4xMywwLDAsMSw3LjIyLDguNzZhMTQuNTYsMTQuNTYsMCwwLDAsMTAuNTksNS4zN0E1LDUsMCwwLDEsMTcuNjgsMTNhNS4xNCw1LjE0LDAsMCwxLDguODktMy41MkExMC4yLDEwLjIsMCwwLDAsMjkuODMsOC4yLDUuMTEsNS4xMSwwLDAsMSwyNy41NywxMWExMC4yMiwxMC4yMiwwLDAsMCwzLS44MUExMC4zMiwxMC4zMiwwLDAsMSwyOCwxMi44OVoiLz48L2c+PC9nPjwvc3ZnPg=="
                    className="hover contact-icon"
                  />
                </a>
                <a href="https://www.linkedin.com/company/2999974">
                  <img
                    src="data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4KPCEtLSBHZW5lcmF0b3I6IEFkb2JlIElsbHVzdHJhdG9yIDIzLjAuMCwgU1ZHIEV4cG9ydCBQbHVnLUluIC4gU1ZHIFZlcnNpb246IDYuMDAgQnVpbGQgMCkgIC0tPgo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9IuWbvuWxgl8xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB4PSIwcHgiIHk9IjBweCIKCSB2aWV3Qm94PSIwIDAgMzYgMzYiIHN0eWxlPSJlbmFibGUtYmFja2dyb3VuZDpuZXcgMCAwIDM2IDM2OyIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSI+Cjx0aXRsZT5Bc3NldCAxPC90aXRsZT4KPGcgaWQ9IkxheWVyXzIiPgoJPGcgaWQ9IkxheWVyXzEtMiI+CgkJPHBhdGggZD0iTTAsMHYzNmgzNlYwSDB6IE0xMC45LDMwLjlINS41VjEzLjdoNS40TDEwLjksMzAuOXogTTguMiwxMS4zYy0xLjcsMC0zLjEtMS40LTMuMS0zLjFjMC0xLjcsMS40LTMuMSwzLjEtMy4xCgkJCXMzLjEsMS40LDMuMSwzLjFjMCwwLDAsMCwwLDBDMTEuMyw5LjksOS45LDExLjMsOC4yLDExLjNDOC4yLDExLjMsOC4yLDExLjMsOC4yLDExLjN6IE0zMC45LDMwLjloLTUuNHYtOC40YzAtMiwwLTQuNi0yLjgtNC42CgkJCXMtMy4yLDIuMi0zLjIsNC40djguNWgtNS4zVjEzLjdoNS4xVjE2aDAuMWMxLTEuOCwzLTIuOSw1LjEtMi44YzUuNCwwLDYuNCwzLjYsNi40LDguMlYzMC45eiIvPgoJPC9nPgo8L2c+Cjwvc3ZnPgo="
                    className="unhover contact-icon"
                  />
                  <img
                    src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAzNiAzNiI+PGRlZnM+PHN0eWxlPi5jbHMtMXtmaWxsOiMyNjRmZmY7fTwvc3R5bGU+PC9kZWZzPjx0aXRsZT5Bc3NldCAxPC90aXRsZT48ZyBpZD0iTGF5ZXJfMiIgZGF0YS1uYW1lPSJMYXllciAyIj48ZyBpZD0iTGF5ZXJfMS0yIiBkYXRhLW5hbWU9IkxheWVyIDEiPjxwYXRoIGNsYXNzPSJjbHMtMSIgZD0iTTAsMFYzNkgzNlYwWk0xMC44NiwzMC45SDUuNVYxMy42N2g1LjM2Wk04LjE4LDExLjMxYTMuMTEsMy4xMSwwLDEsMSwzLjExLTMuMUEzLjEsMy4xLDAsMCwxLDguMTgsMTEuMzFaTTMwLjkyLDMwLjlIMjUuNTdWMjIuNTJjMC0yLDAtNC41Ny0yLjc4LTQuNTdzLTMuMjIsMi4xOC0zLjIyLDQuNDNWMzAuOUgxNC4yMlYxMy42N2g1LjE0VjE2aC4wN2E1LjYzLDUuNjMsMCwwLDEsNS4wNy0yLjc4YzUuNDIsMCw2LjQyLDMuNTcsNi40Miw4LjIxWiIvPjwvZz48L2c+PC9zdmc+"
                    className="hover contact-icon"
                  />
                </a>
                <a href="https://www.youtube.com/channel/UC-clVzYI64VeMTQpb_lo3Ow">
                  <img
                    src="data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4KPCEtLSBHZW5lcmF0b3I6IEFkb2JlIElsbHVzdHJhdG9yIDI1LjEuMCwgU1ZHIEV4cG9ydCBQbHVnLUluIC4gU1ZHIFZlcnNpb246IDYuMDAgQnVpbGQgMCkgIC0tPgo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkxheWVyXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4IgoJIHZpZXdCb3g9IjAgMCAzNiAzNiIgc3R5bGU9ImVuYWJsZS1iYWNrZ3JvdW5kOm5ldyAwIDAgMzYgMzY7IiB4bWw6c3BhY2U9InByZXNlcnZlIj4KPHBvbHlnb24gcG9pbnRzPSIyMy45LDE4IDE0LDIzLjkgMTQsMTIuMiAyMy45LDE4ICIvPgo8cGF0aCBkPSJNMCwwdjM2aDM2VjBIMHogTTMzLjIsMjMuNGMwLDUuMi01LjIsNS4yLTUuMiw1LjJIOC4xYy01LjIsMC01LjItNS4yLTUuMi01LjJWMTIuNmMwLTUuMiw1LjItNS4yLDUuMi01LjJoMTkuOQoJYzUuMiwwLDUuMiw1LjIsNS4yLDUuMlYyMy40eiIvPgo8L3N2Zz4K"
                    className="unhover contact-icon"
                  />
                  <img
                    src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA4MCA4MCI+PGRlZnM+PHN0eWxlPi5jbHMtMXtmaWxsOiMyNjUwZmY7fTwvc3R5bGU+PC9kZWZzPjx0aXRsZT5Zb3VUdWJlIDM8L3RpdGxlPjxnIGlkPSLlm77lsYJfMiIgZGF0YS1uYW1lPSLlm77lsYIgMiI+PGcgaWQ9IuWbvuWxgl8xLTIiIGRhdGEtbmFtZT0i5Zu+5bGCIDEiPjxwYXRoIGNsYXNzPSJjbHMtMSIgZD0iTTMzLjYzLDQ5Ljc3LDUwLjMyLDQwLDMzLjYzLDMwLjMxWiIvPjxwYXRoIGNsYXNzPSJjbHMtMSIgZD0iTTAsMFY4MEg4MFYwWk03Miw0MS4yNWE4NC43OSw4NC43OSwwLDAsMS0uODMsMTJjLS4zNiwyLjQ1LTEsNC43LTMsNi4zOWE5Ljc2LDkuNzYsMCwwLDEtNS43NSwyLjEsMjUwLjI0LDI1MC4yNCwwLDAsMS0yOS44Ny43OWMtNC0uMTEtOC0uMjgtMTIuMDYtLjUzYTI3LjEsMjcuMSwwLDAsMS01LjYyLS44Yy0zLjQtLjk1LTUuMjYtMy40NC01Ljc2LTYuODYtLjQyLTIuODUtLjc0LTUuNzItLjkyLTguNTlhOTYuNDIsOTYuNDIsMCwwLDEsLjI3LTE1LjIsNDAuODksNDAuODksMCwwLDEsLjkxLTUuOTUsOCw4LDAsMCwxLDcuMTktNi4xNmM3LjgxLTEsMTUuNjUtLjg5LDI1LjMxLTEuMTMsNC43MS4xOSwxMS4yMy40LDE3Ljc1Ljc0YTI1LjI4LDI1LjI4LDAsMCwxLDUuODEuODgsNy42LDcuNiwwLDAsMSw1LjQ2LDYuMzRBODAuNDcsODAuNDcsMCwwLDEsNzIsNDEuMjVaIi8+PC9nPjwvZz48L3N2Zz4="
                    className="hover contact-icon"
                  />
                </a>
              </li>
            </ul>
            <ul className="lang-list">
              <li className="text-2xl cursor-pointer selected">
                <a href="http://wp.cc/">简体中文</a>
              </li>
              <li data-v-0770328d="" className="text-2xl cursor-pointer ">
                <a href="http://wp.cc/en/">English</a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </>
  );
};

export default Header;
