import {
  Box,
  Button,
  Card,
  FormControl,
  Grid,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import "../index.css";
import React from "react";

const OnlineBilling = () => {
  const [selectedProvince, setSelectedProvince] = React.useState("");
  const [selectedCity, setSelectedCity] = React.useState("");

  const handleProvinceChange = (value: string) => {
    setSelectedProvince(value);
  };

  const handleCityChange = (value: string) => {
    setSelectedCity(value);
  };

  return (
    <>
      <Card
        sx={{
          width: "80%",
          margin: "auto",
          borderRadius: 5,
          marginTop: 3,
          marginBottom: 15,
        }}
      >
        <Grid container spacing={2} padding={3} alignItems="center">
          <Grid item xs={12}>
            <Typography variant="h5">在线下单</Typography>
          </Grid>
          {/* 收货人信息 */}
          <Grid item xs={6}>
            {/* 收货人图标 */}
            <Grid item xs={12} display={"flex"} padding={1}>
              <Box
                sx={{
                  width: "30px",
                  height: "30px",
                  borderRadius: "50%", // 设置为50%将其变成圆形
                  backgroundColor: "#FFBE00", // 黑色背景

                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  marginRight: 2,
                }}
              >
                收
              </Box>

              <Typography height="30px" lineHeight="30px" variant="subtitle2">
                收货人信息
              </Typography>
            </Grid>

            {/* 收货人填写 */}
            <Grid item xs={12} display={"flex"}>
              <Grid item xs={4} padding={1}>
                <TextField
                  label="收货人姓名"
                  fullWidth
                  margin="normal"
                  variant="outlined"
                  size="small"
                />
              </Grid>

              <Grid item xs={4} padding={1}>
                <TextField
                  label="收货人电话"
                  fullWidth
                  margin="normal"
                  variant="outlined"
                  size="small"
                />
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={6}>
            {/* 发货人图标 */}
            <Grid item xs={12} display={"flex"} padding={1}>
              <Box
                sx={{
                  width: "30px",
                  height: "30px",
                  borderRadius: "50%", // 设置为50%将其变成圆形
                  backgroundColor: "#000", // 黑色背景
                  color: "#fff", // 白色文字
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  marginRight: 2,
                }}
              >
                寄
              </Box>

              <Typography height="30px" lineHeight="30px" variant="subtitle2">
                发货人信息
              </Typography>
            </Grid>
            <Grid item xs={12} display={"flex"}>
              <Grid item xs={4} padding={1}>
                <TextField
                  label="发货人姓名"
                  fullWidth
                  margin="normal"
                  variant="outlined"
                  size="small"
                />
              </Grid>

              <Grid item xs={4} padding={1}>
                <TextField
                  label="发货人电话"
                  fullWidth
                  margin="normal"
                  variant="outlined"
                  size="small"
                />
              </Grid>
            </Grid>
          </Grid>
          {/* 货物信息*/}
          <Grid item xs={6}>
            <Grid item xs={12} display={"flex"} padding={1}>
              <Typography variant="subtitle2">货物信息</Typography>
            </Grid>
            <Grid item xs={12} display={"flex"}>
              <Grid item xs={3} padding={1}>
                <TextField
                  label="货物名称"
                  fullWidth
                  margin="normal"
                  variant="outlined"
                  size="small"
                />
              </Grid>

              <Grid item xs={2} padding={1}>
                <TextField
                  label="件数"
                  fullWidth
                  margin="normal"
                  variant="outlined"
                  type="number"
                  size="small"
                  InputProps={{
                    inputProps: {
                      min: 0,
                    },
                  }}
                />
              </Grid>

              <Grid item xs={2} padding={1}>
                <TextField
                  label="重量"
                  fullWidth
                  margin="normal"
                  variant="outlined"
                  size="small"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">kg</InputAdornment>
                    ),
                  }}
                />
              </Grid>

              <Grid item xs={2} padding={1}>
                <TextField
                  label="体积"
                  fullWidth
                  margin="normal"
                  variant="outlined"
                  size="small"
                />
              </Grid>
            </Grid>
          </Grid>
          {/* 收货地址 */}
          <Grid item xs={6}>
            <Grid item xs={12} display={"flex"} padding={1}>
              <Typography variant="subtitle2">收货地址</Typography>
            </Grid>
            <Grid item xs={12} display={"flex"}>
              <Grid item xs={3} padding={1}>
                <FormControl
                  fullWidth
                  variant="outlined"
                  size="small"
                  margin="normal"
                >
                  <InputLabel>省份</InputLabel>
                  <Select
                    label="省份"
                    value={selectedProvince}
                    onChange={async (event) =>
                      handleProvinceChange(event.target.value)
                    }
                  >
                    <MenuItem value="province1">贵阳</MenuItem>
                    <MenuItem value="province2">上海</MenuItem>
                    {/* 添加更多省份 */}
                  </Select>
                </FormControl>
              </Grid>

              <Grid item xs={3} padding={1}>
                <FormControl
                  fullWidth
                  variant="outlined"
                  size="small"
                  margin="normal"
                >
                  <InputLabel>市区</InputLabel>
                  <Select
                    label="市区"
                    value={selectedCity}
                    onChange={async (event) =>
                      handleCityChange(event.target.value)
                    }
                  >
                    <MenuItem value="city1">六盘水</MenuItem>
                    <MenuItem value="city2">普陀区</MenuItem>
                    {/* 添加更多市区 */}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={3} padding={1}>
                <FormControl
                  fullWidth
                  variant="outlined"
                  size="small"
                  margin="normal"
                >
                  <InputLabel>县区</InputLabel>
                  <Select
                    label="县区"
                    value={selectedCity}
                    onChange={async (event) =>
                      handleCityChange(event.target.value)
                    }
                  >
                    <MenuItem value="city1">六盘水</MenuItem>
                    <MenuItem value="city2">普陀区</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
          </Grid>

          {/* 代收款  */}
          <Grid item xs={12}>
            <Grid item xs={2} padding={1}>
              <TextField
                label="代收款"
                fullWidth
                margin="normal"
                variant="outlined"
                size="small"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">¥</InputAdornment>
                  ),
                }}
              />
            </Grid>
          </Grid>
        </Grid>

        <Box marginTop={5}>
          <Grid
            container
            style={{
              background: "rgb(255, 188, 7)",
              height: 100,
              display: "flex",
              alignItems: "center",
            }}
            spacing={2}
          >
            <Grid item xs={10} style={{ textAlign: "right" }}>
              <Grid container justifyContent="flex-end">
                <Grid item>预估总价 - 元起</Grid>
              </Grid>
            </Grid>
            <Grid item xs={2} style={{ textAlign: "center" }}>
              <Button
                variant="contained"
                fullWidth
                style={{ width: 100, height: 50, background: "#242424" }}
              >
                下单
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Card>
    </>
  );
};
export default OnlineBilling;
