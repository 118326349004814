import * as React from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { Grid } from "@mui/material";
import LogisticsFranchiseFAQ from "./components/LogisticsFAQ";
import FranchiseApplicationForm from "./components/FranchiseApplicationForm";
import JoinAdvantages from "./components/JoinAdvantages";
import SuccessCase from "./components/SuccessCase";
import OpenRegion from "./components/OpenRegion";

export default function Investment() {
  const [value, setValue] = React.useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  return (
    <Grid container>
      <Grid item xs={12} margin={"auto"}>
        <img
          src="/images/20220610101714.jpg"
          style={{ width: "100%", height: "700px" }}
        />
      </Grid>
      <Grid container>
        <Grid item xs={12}>
          <Tabs
            value={value}
            onChange={handleChange}
            sx={{
              width: "100%",
              display: "flex",
            }}
            centered
          >
            <Tab label="加盟介绍" sx={{ flex: 1 }} />
            <Tab label="开放区域" sx={{ flex: 1 }} />
            <Tab label="常见问题" sx={{ flex: 1 }} />
            <Tab label="成功案例" sx={{ flex: 1 }} />
            <Tab label="加盟申请" sx={{ flex: 1 }} />
          </Tabs>
        </Grid>
        <Grid item xs={12}>
          {value === 0 && <JoinAdvantages />}
          {value === 1 && <OpenRegion />}
          {value === 2 && <LogisticsFranchiseFAQ />}
          {value === 3 && <SuccessCase />}
          {value === 4 && <FranchiseApplicationForm />}
        </Grid>
      </Grid>
    </Grid>
  );
}
