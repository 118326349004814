import { Box, Card, Divider, Grid, Typography } from "@mui/material";
import React from "react";

const Join = () => {
  return (
    <>
      <Box padding={5}>
        <Divider component="div" role="presentation">
          {/* any elements nested inside the role="presentation" preserve their semantics. */}
          <Typography variant="h4">基础操作岗</Typography>
        </Divider>
        <Grid
          container
          spacing={5}
          padding={5}
          display={"flex"}
          justifyContent={"center"}
        >
          <Grid item xs={4}>
            <Card sx={{ padding: 5, height: 320 }}>
              <Typography variant="h5" padding={1}>
                收货员
              </Typography>
              <Typography padding={1}>岗位要求：</Typography>
              <Typography padding={1}>
                ①负责货物数量的清点，杠号，称重，量方
              </Typography>
              <Typography padding={1}>
                ②年龄在20周岁以上，可接收应届毕业生
              </Typography>
              <Typography padding={1}>
                ③责任心强，诚信、细致，有较强的团队合作意识
              </Typography>
              <Typography padding={1}>④有物流公司工作经验者优先</Typography>
            </Card>
          </Grid>

          <Grid item xs={4}>
            <Card sx={{ padding: 5, height: 320 }}>
              <Typography variant="h5" padding={1}>
                接货员
              </Typography>
              <Typography padding={1}>岗位要求：</Typography>

              <Typography padding={1}>
                ①熟悉周边客户；物流市场周边接货、送货;
              </Typography>
              <Typography padding={1}>
                ②负责货物数量的清点，杠号，称重，量方;
              </Typography>
            </Card>
          </Grid>
        </Grid>
        <Divider component="div" role="presentation">
          {/* any elements nested inside the role="presentation" preserve their semantics. */}
          <Typography variant="h4">基础文职岗</Typography>
        </Divider>
        <Grid
          container
          spacing={5}
          padding={5}
          display={"flex"}
          justifyContent={"center"}
        >
          <Grid item xs={4}>
            <Card sx={{ padding: 5, height: 450 }}>
              <Typography variant="h5" padding={1}>
                综合文员
              </Typography>

              <Typography padding={1}>岗位要求：</Typography>
              <Typography padding={1}>
                ①负责公司各类电脑文档的编号、打印、排版和归档；
              </Typography>
              <Typography padding={1}>
                ②报表的收编以及数据统计整理，以便更好的贯彻和落实工作；
              </Typography>
              <Typography padding={1}>
                ③协调会议室预定，合理安排会议室的使用；
              </Typography>
              <Typography padding={1}>
                ④会议室环境的日常维护工作，确保办公区的整洁有序;
              </Typography>
              <Typography padding={1}>⑤完成领导交代的其它工作。</Typography>
            </Card>
          </Grid>

          <Grid item xs={4}>
            <Card sx={{ padding: 5, height: 450 }}>
              <Typography variant="h5" padding={1}>
                人事专员
              </Typography>
              <Typography padding={1}>岗位要求：</Typography>
              <Typography padding={1}>
                ①全面负责公司招聘工作，甄别、选择、建立和维护合适的招聘渠道；
              </Typography>
              <Typography padding={1}>
                ②负责筛选合适的简历并进行电话邀约，做好求职者的接待和跟进工作
              </Typography>
              <Typography padding={1}>
                ③办理员工入职、转正、离职、考勤、社保等人事流程的申请和办理；
              </Typography>
              <Typography padding={1}>
                ④负责整理公司员工劳动合同及相关人事资料的整理;
              </Typography>
              <Typography padding={1}>
                ⑤及时进行数据更新工作，完成上级主管给予的任务;
              </Typography>
            </Card>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default Join;
