import { Button, TextField } from "@mui/material";
import React, { useState } from "react";

const ComplaintForm = () => {
  const [formData, setFormData] = useState({
    name: "",
    phoneNumber: "",
    complaintContent: "",
  });

  const handleChange = (event: any) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = (event: any) => {
    event.preventDefault();
    // 处理提交逻辑，可以使用 formData 中的数据
    console.log(formData);
  };

  return (
    <form onSubmit={handleSubmit}>
      <TextField
        label="姓名"
        variant="outlined"
        fullWidth
        name="name"
        value={formData.name}
        onChange={handleChange}
        margin="normal"
      />
      <TextField
        label="手机号"
        variant="outlined"
        fullWidth
        name="phoneNumber"
        value={formData.phoneNumber}
        onChange={handleChange}
        margin="normal"
      />
      <TextField
        label="投诉内容"
        variant="outlined"
        fullWidth
        multiline
        rows={4}
        name="complaintContent"
        value={formData.complaintContent}
        onChange={handleChange}
        margin="normal"
      />
      <Button type="submit">提交</Button>
    </form>
  );
};

export default ComplaintForm;
