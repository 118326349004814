import * as React from "react";
import {
  Autocomplete,
  List,
  ListItem,
  ListItemText,
  TextField,
} from "@mui/material";
import { useEffect, useState } from "react";
import { fetchStation } from "../services";

const LineForm = () => {
  //   const [loading, setLoading] = useState(false);
  const [stations, setStations] = useState<Station[]>([]);
  const [selectedLocation, setSelectedLocation] = useState<string | null>(null);
  const [uniqueLocationNames, setUniqueLocationNames] = useState<string[]>([]);

  useEffect(() => {
    const fetchDataWithRetry = async () => {
      try {
        const response = await fetchStation();
        const filteredStations = response.data.list.filter(
          (station: Station) => station.status !== 0
        );

        const uniqueNames = Array.from(
          new Set(
            filteredStations.map(
              (station: Station) => station.locationName
            ) as unknown as string[]
          )
        );

        setStations(filteredStations);
        setUniqueLocationNames(uniqueNames);
      } catch (error) {
        console.error("Request failed. Waiting and retrying...", error);

        // Wait for 3 seconds
        await new Promise((resolve) => setTimeout(resolve, 3000));

        // Retry the fetchDataWithRetry function
        await fetchDataWithRetry();
      }
    };

    // Call the fetchDataWithRetry function when the component mounts
    fetchDataWithRetry();
  }, []);

  //   async function handleClick() {
  //     if (selectedLocation) {
  //       const filteredList = stations.filter(
  //         (station) => station.locationName === selectedLocation
  //       );
  //     }
  //   }

  return (
    <div className="container-item">
      <div className="form-item">
        <TextField
          id="to"
          label="起始点"
          multiline
          value={"临沂"}
          variant="standard"
        />
        <Autocomplete
          id="disable-close-on-select"
          options={uniqueLocationNames}
          style={{ width: 200 }}
          onChange={(event, value) => {
            setSelectedLocation(value);
          }}
          renderInput={(params) => (
            <TextField {...params} label="目的地" variant="standard" />
          )}
        />
      </div>

      <div className="lists">
        <List className="lists-item">
          {stations
            .filter((station) => station.locationName === selectedLocation)
            .map((filteredStation) => (
              <ListItem alignItems="flex-start" key={filteredStation.id}>
                <ListItemText
                  primary={filteredStation.locationName}
                  secondary={
                    <React.Fragment>
                      {filteredStation.address}
                      {filteredStation.businessPhone
                        .split("/")
                        .map((phoneNumber, index) => (
                          <a
                            key={index}
                            href={`tel:${phoneNumber.trim()}`}
                            className="list-phone"
                          >
                            {phoneNumber.trim()}
                          </a>
                        ))}
                    </React.Fragment>
                  }
                />
              </ListItem>
            ))}
        </List>
      </div>
    </div>
  );
};

export default LineForm;
