import { Grid, Tab, Tabs } from "@mui/material";
import React from "react";
import AboutUs from "./components/aboutus";
import StoryTimeline from "./components/storyTimeline";
import Honor from "./components/honor";
import Culture from "./components/culture";
import { useLocation } from "react-router-dom";

const About = () => {
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const defaultValue = parseInt(params.get("tab") || "0", 10);
  const [value, setValue] = React.useState(defaultValue);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };
  return (
    <>
      <Grid container>
        <Grid item xs={12}>
          <img
            src="/images/20220610101714.jpg"
            style={{ width: "100%", height: "700px" }}
          />
        </Grid>

        <Grid item xs={12}>
          <Tabs value={value} onChange={handleChange} centered>
            <Tab label="企业简介" sx={{ flex: 1 }} />
            <Tab label="发展历程" sx={{ flex: 1 }} />
            <Tab label="企业文化" sx={{ flex: 1 }} />
            <Tab label="企业荣誉" sx={{ flex: 1 }} />
          </Tabs>
        </Grid>
        <Grid item xs={12}>
          {value === 0 && <AboutUs />}
          {value === 1 && <StoryTimeline />}
          {value === 2 && <Culture />}
          {value === 3 && <Honor />}
        </Grid>
      </Grid>
    </>
  );
};

export default About;
