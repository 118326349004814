import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { StyledTableCell, StyledTableRow, createData } from "../tableStyle";

const YunnanStation = () => {
  const rows = [
    createData("云南", "呈贡区林溪路倪家营豪运物流园1栋10号", "13320525036"),
    createData("云南", "呈贡区林溪路倪家营豪运物流园1栋10号", "13064263069"),
  ];

  return (
    <TableContainer component={Paper}>
      <Table aria-label="shandong">
        <TableHead>
          <TableRow>
            <StyledTableCell>云南昆明卸货网点</StyledTableCell>
            <StyledTableCell>地址</StyledTableCell>
            <StyledTableCell>电话</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row, index) => (
            <StyledTableRow key={index}>
              <StyledTableCell component="th" scope="row">
                {row.name}
              </StyledTableCell>
              <StyledTableCell>{row.address}</StyledTableCell>
              <StyledTableCell>
                <a href="tel:${row.phone}"> {row.phone} </a>
              </StyledTableCell>
            </StyledTableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default YunnanStation;
