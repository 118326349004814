import * as React from "react";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { StyledTableCell, StyledTableRow, createData } from "../tableStyle";

const XinjiangStation = () => {
  const rows = [
    createData(
      "乌鲁木齐",
      "乌鲁木齐市兵团物流园南区新潮广院子B15-16号",
      "0991-5631777"
    ),
    createData(
      "乌鲁木齐",
      "乌鲁木齐市兵团物流园南区新潮广院子B15-16号",
      "15349996777"
    ),
    createData("喀什", "喀什市新远方国际物流港D区105--106号", "0998-2656777"),
    createData("喀什", "喀什市新远方国际物流港D区105--106号", "15276108777"),
    createData("哈密", "哈密天顺物流园西胜物流D-03", "0902-2350448"),
    createData("哈密", "哈密天顺物流园西胜物流D-03", "18999034533"),
    createData("库尔勒", "库尔勒市华凌物流园新1区3-6", "15109963600"),
    createData("阿克苏", "阿克苏市迎宾大道南疆酒业旁大院内", "18196396789"),
    createData("和田", "和田飞机场附近红枣交易市场C区1-008号", "17397846858"),
    createData("和田", "和田飞机场附近红枣交易市场C区1-008号", "0903-6870005"),
    createData("莎车", "莎车县米夏镇北郊物流园4-5-6", "13565660113"),
  ];

  return (
    <TableContainer component={Paper}>
      <Table aria-label="shandong">
        <TableHead>
          <TableRow>
            <StyledTableCell>新疆卸货点</StyledTableCell>
            <StyledTableCell>地址</StyledTableCell>
            <StyledTableCell>电话</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row, index) => (
            <StyledTableRow key={index}>
              <StyledTableCell component="th" scope="row">
                {row.name}
              </StyledTableCell>
              <StyledTableCell>{row.address}</StyledTableCell>
              <StyledTableCell>
                <a href="tel:${row.phone}"> {row.phone} </a>
              </StyledTableCell>
            </StyledTableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default XinjiangStation;
