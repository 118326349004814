import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { StyledTableCell, StyledTableRow, createData } from "../tableStyle";

const ShandongStation = () => {
  const shandongRows = [
    createData("济南", "二环北路金天龙物流园（第三家祥源物流）", "15376217838"),
    createData("章丘", "章丘明水眼科医院向北300米", "13305319393"),
    createData("济阳", "济阳区经二路北段路东（气象局北200米）", "17686603567"),
    createData("德州", "新华工业园海天庄园对过华阳物流", "0534-2326958"),
    createData(
      "齐河",
      "齐河县国道308齐河大桥北侧安迪汽修厂院内",
      "17662406968"
    ),
    createData("禹城", "人民路与南外环交叉口南行100米路西", "15266962099"),
    createData("临邑", "临邑县公交公司门口", "17862680666"),
    createData(
      "青岛",
      "城阳区仙山东路63号聚理黑豹公路港B区胡大姐物流",
      "0532-87877899"
    ),
    createData("胶州", "胶州南方家园E3-7宸昇物流", "13070833530"),
    createData("胶南", "隐珠山路1388号临港海达物流园保时通物流", "13220858223"),
    createData("黄岛", "前湾港路472号普天物流", "18325427127"),
    createData(
      "滨州",
      "滨城区黄河路与渤海四路交叉路口向北二百米路西",
      "13336290517"
    ),
    createData("无棣", "无棣通立商贸城", "13336290517"),
    createData("沾化", "沾化丁和家园", "13336290517"),
    createData("阳信", "阳信幸福一路与阳城四路交叉口向南20米", "13336290517"),
    createData("惠民", "惠民商贸城无棣", "13336290517"),

    createData("博兴", "博兴大世界北门", "15206876555"),

    createData(
      "潍坊",
      "胜利街与腾飞路西500米西门路扬物流A库32号",
      "18053696158"
    ),

    createData(
      "昌乐",
      "宝通街与大沂路往南1000米路西公交公司北邻",
      "0536-2186186"
    ),
    createData(
      "昌邑",
      "北海路汽车检测线北200米路东（星光大道院内）",
      "0536-7227831"
    ),
    createData(
      "青州",
      "凤凰山路与昭得路交汇东200米路北三中北门对面",
      "0536-2489885"
    ),
    createData("临朐", "江北物流城E栋03,05号", "15866503118"),
    createData(
      "寿光",
      "寿光国际商贸城C区C2排25-27商铺鹏源物流",
      "0536-5100220"
    ),
    createData("高密", "夷安大道与朝阳大街交叉路口西100米路北", "15666237577"),
    createData(
      "安丘",
      "潍安路与南苑路十字路口西100米路南腾达物流",
      "13964776270"
    ),
    createData(
      "东营",
      "北一路太行山路路口东南角齐鲁通力五金院内",
      "13306478610"
    ),
    createData("广饶", "小义乌物流西广场东北角", "18678638222"),
    createData("垦利", "冯屋商业街", "13306478610"),
    createData("河口", "三义河建材城路", "13306478610"),
    createData("利津", "利津百货大楼", "13336290517"),
    createData("淄博", "淄博市张店区城东路98号", "15864493563"),
    createData("淄川", "淄川区淄川立交桥向东10米路南", "18615125666"),
    createData("博山", "博山区张博路正堂酿造厂斜对面院内", "0533-8055388"),
    createData(
      "桓台",
      "桓台大道花园路北10米路西花园仓储物流园内",
      "18353306393"
    ),
    createData("邹平", "邹平黛溪一路路北（交通事故处理科）旁", "13355222756"),
    createData("周村", "周村北方不锈钢市场1-3号", "18560323607"),
    createData("高青", "高青县文化路南首路西（老地毯厂院内）", "15905335651"),
    createData(
      "沂源",
      "沂源县振兴路与瑞阳大道交汇处东300米路北",
      "0533-3252222"
    ),
    createData("临淄", "临淄区辛化路158号", "18353306393"),
  ];

  return (
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            <StyledTableCell>山东专线卸货网点</StyledTableCell>
            <StyledTableCell>总部业务电话</StyledTableCell>
            <StyledTableCell>地址</StyledTableCell>
            <StyledTableCell>电话</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {shandongRows.map((row, index) => (
            <StyledTableRow key={index}>
              <StyledTableCell component="th" scope="row">
                {row.name}
              </StyledTableCell>
              <StyledTableCell>
                <a href="tel:${row.phone}"> {row.phone} </a>
              </StyledTableCell>
              <StyledTableCell>{row.address}</StyledTableCell>
              <StyledTableCell>
                <a href="tel:${row.phone}"> {row.phone} </a>
              </StyledTableCell>
            </StyledTableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default ShandongStation;
