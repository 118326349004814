import {
  Autocomplete,
  Card,
  Grid,
  List,
  ListItem,
  ListItemText,
  TextField,
  Typography,
} from "@mui/material";
import AnhuiStation from "./components/station/anhuiStation";
import GansuStation from "./components/station/gansuStation";
import GuiyangStation from "./components/station/guiyangStation";
import HebeiStation from "./components/station/hebeiStation";
import HenanStation from "./components/station/henanStation";
import HubeiStation from "./components/station/hubeiStation";
import HunanStation from "./components/station/hunanStation";
import JiangsuStation from "./components/station/jiangsuStation";
import NingxiaStation from "./components/station/ningxiaStation";
import QinghaiStation from "./components/station/qinghaiStation";
import ShandongStation from "./components/station/shandongStation";
import ShanxiStation from "./components/station/shanxiStation";
import XinjiangStation from "./components/station/xinjiangStation";
import XizangStation from "./components/station/xizangStation";
import YunnanStation from "./components/station/yunnanStation";
import { useEffect, useState } from "react";
import { fetchStation } from "../home/services";
import React from "react";
import { LoadingButton } from "@mui/lab";

const Stations = () => {
  const [stations, setStations] = useState<Station[]>([]);
  const [selectedLocation, setSelectedLocation] = useState<string | null>(null);
  const [uniqueLocationNames, setUniqueLocationNames] = useState<string[]>([]);
  const [isCardVisible, setCardVisible] = useState(false);
  const [openAutocomplete, setOpenAutocomplete] = useState(false);
  const [loading, setLoading] = React.useState(false);

  const handleButtonClick = () => {
    if (selectedLocation != null) {
      setLoading(true);
      setTimeout(() => {
        setLoading(false);
        setCardVisible(true);
      }, 2000);
    } else {
      // Trigger Autocomplete query by opening the dropdown
      setOpenAutocomplete(true);
    }
  };

  const [openProvince, setOpenProvince] = useState(false);
  const province = ["山东", "云南", "贵州", "四川"];

  useEffect(() => {
    const fetchDataWithRetry = async () => {
      try {
        const response = await fetchStation();
        const filteredStations = response.data.list.filter(
          (station: Station) => station.status !== 0
        );

        const uniqueNames = Array.from(
          new Set(
            filteredStations.map(
              (station: Station) => station.locationName
            ) as unknown as string[]
          )
        );

        setStations(filteredStations);
        setUniqueLocationNames(uniqueNames);
      } catch (error) {
        console.error("Request failed. Waiting and retrying...", error);

        // Wait for 3 seconds
        await new Promise((resolve) => setTimeout(resolve, 3000));

        // Retry the fetchDataWithRetry function
        await fetchDataWithRetry();
      }
    };
    fetchDataWithRetry();
  }, []);

  return (
    <>
      <Grid container display={"flex"} justifyContent={"center"} marginTop={5}>
        <Grid item xs={12} lg={10}>
          <Card
            sx={{
              margin: "auto",
              borderRadius: 5,
            }}
          >
            <Grid container spacing={2} padding={3} alignItems="center">
              <Grid item xs={12}>
                <Typography variant="h5">网点查询</Typography>
              </Grid>
              <Grid item xs={3}>
                <TextField fullWidth value={"临沂"} variant="outlined" />
              </Grid>
              <Grid item xs={3}>
                <Autocomplete
                  options={province}
                  open={openProvince}
                  onOpen={() => setOpenProvince(true)}
                  onClose={() => setOpenProvince(false)}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="选择省份"
                      variant="outlined"
                    />
                  )}
                />
              </Grid>
              <Grid item xs={4}>
                <Autocomplete
                  options={uniqueLocationNames}
                  open={openAutocomplete}
                  onOpen={() => setOpenAutocomplete(true)}
                  onClose={() => setOpenAutocomplete(false)}
                  onChange={(event, value) => {
                    setCardVisible(false);
                    setSelectedLocation(value);
                  }}
                  renderInput={(params) => (
                    <TextField {...params} label="目的地" variant="outlined" />
                  )}
                />
              </Grid>
              <Grid item xs={2}>
                <LoadingButton
                  variant="contained"
                  fullWidth
                  onClick={handleButtonClick}
                  loading={loading}
                  style={{ height: 50, background: "#ffbc07" }}
                >
                  查询
                </LoadingButton>
              </Grid>
            </Grid>
          </Card>
        </Grid>

        {isCardVisible && (
          <Grid item xs={12} lg={10}>
            <Card
              sx={{
                margin: "auto",
                borderRadius: 5,
                marginTop: 3,
              }}
            >
              <List>
                {stations
                  .filter(
                    (station) => station.locationName === selectedLocation
                  )
                  .map((filteredStation) => (
                    <ListItem alignItems="flex-start" key={filteredStation.id}>
                      <ListItemText
                        primary={filteredStation.locationName}
                        secondary={
                          <React.Fragment>
                            {filteredStation.address}
                            {filteredStation.businessPhone
                              .split("/")
                              .map((phoneNumber, index) => (
                                <a
                                  key={index}
                                  href={`tel:${phoneNumber.trim()}`}
                                  className="list-phone"
                                >
                                  {phoneNumber.trim()}
                                </a>
                              ))}
                          </React.Fragment>
                        }
                      />
                    </ListItem>
                  ))}
              </List>
            </Card>
          </Grid>
        )}

        <Grid item xs={12} lg={10} marginTop={5}>
          <ShandongStation />
          <HenanStation />
          <JiangsuStation />
          <AnhuiStation />
          <HebeiStation />
          <ShanxiStation />
          <HebeiStation />
          <ShanxiStation />
          <HunanStation />
          <HubeiStation />
          <YunnanStation />
          <GuiyangStation />
          <NingxiaStation />
          <GansuStation />
          <QinghaiStation />
          <XizangStation />
          <XinjiangStation />
        </Grid>
      </Grid>
    </>
  );
};

export default Stations;
