import React from "react";
import { Typography, Divider, Grid } from "@mui/material";

const LogisticsFranchiseFAQ = () => {
  return (
    <Grid
      container
      display={"flex"}
      justifyContent={"center"}
      spacing={3}
      padding={5}
    >
      <Grid item xs={10} lg={10}>
        <Typography variant="h5" sx={{ mb: 2 }}>
          壹米滴答加盟常见问题
        </Typography>
      </Grid>

      <Grid item xs={10} lg={10}>
        <Typography variant="h6">1. 加盟条件</Typography>
        <Typography>
          想加盟壹米滴答，您需要满足以下条件：
          <ul>
            <li>认同壹米滴答发展理念，有相同的发展愿景。</li>
            <li>具备揽货能力及基本配送能力。</li>
            <li>统一使用信息系统、资金账户、统一网点经营、统一品牌形象。</li>
            <li>接受壹米滴答业务量考核，严格执行运营标准，并接受管控考核。</li>
            <li>缴纳一定的押金、系统使用费等费用。</li>
          </ul>
        </Typography>
      </Grid>

      <Divider sx={{ my: 2 }} />
      <Grid item xs={10} lg={10}>
        <Typography variant="h6">2. 产品竞争力</Typography>
        <Typography>
          如果您有多年物流网点经营管理经验，壹米滴答的产品具有竞争力，成员企业均为各区域领先物流。我们主营产品“县镇通”以末端无盲点的派送优势加以省内中转无缝对接支撑，无论是在网络覆盖，价格、时效方面均能提供保障。
        </Typography>
      </Grid>

      <Divider sx={{ my: 2 }} />
      <Grid item xs={10} lg={10}>
        <Typography variant="h6">3. 无经验加盟</Typography>
        <Typography>
          如果您没有货运行业经验，也可以加盟。壹米滴答拥有成熟的运营和经营平台，我们会提供统一的培训、系统、金融等资源支持。您只需要按照总部指导方案经营即可。
        </Typography>
      </Grid>
    </Grid>
  );
};

export default LogisticsFranchiseFAQ;
