import React from 'react';
import { Link } from 'react-router-dom';
import "./footer.css"
const Footer = () =>  {
  
  return (
    <div className='footer-container'>

      <div className='footer-links'>
      <div className='footer-link-wrapper'>
          <div className='footer-link-items'>
          <img src="blogo.png" className='footer-logo'/>
          </div>
        </div>
    
        <div className='footer-link-wrapper'>
          <div className='footer-link-items'>
            <h2>企业介绍</h2>
            <Link to='/sign-up'>公司简介</Link>
            <Link to='/'>发展历程</Link>
            <Link to='/'>企业文化</Link>
            <Link to='/'>企业荣誉</Link>
          </div>

          <div className='footer-link-items'>
            <h2>寄件</h2>
            <Link to='/'>寄件查询</Link>
            <Link to='/'>网点查询</Link>
          </div>
        </div>
        
        <div className='footer-link-wrapper'>
          <div className='footer-link-items'>
            <h2>加盟投资</h2>
            <Link to='/'>招商加盟</Link>
          </div>
          <div className='footer-link-items'>
            <h2>人才计划</h2>
            <Link to='/'>人才招聘</Link>
          </div>
          <div className='footer-link-items'>
            <h2>联系我们</h2>
            <a>邮编:201700</a>
            <a>总部地址:山东省临沂市兰山区临西十路与大山路交汇(兴华万通物流园)</a>
            <Link to='/'>投诉建议</Link>
          </div>
        </div>
        
        <div className='footer-link-wrapper'>
          <div className='footer-link-items'>
            <img src="./images/qrcode.jpeg" className='footer-qrcode' />
          </div>
        </div>

        <div className='footer-link-wrapper'>
          <div className='footer-link-items'>
            <h2>客服热线</h2>
            <div className='footer-contact'>
              <a href='tel:4000009777' className='footer-phone'>400009777</a>
            </div>
            <div style={{ marginTop:20}}>
            <i className="fa-solid fa-headset fa-2xl" style={{ color: "#555555"}} ></i>
            </div>

          </div>
        </div>

      
      </div>
      <section className='social-media'>
        <div className='social-media-wrap'>
          <div className='footer-logo'>
          </div>
          <small className='website-rights'>兴华万通© 2023</small>
          <small className='website-rights'>备案号</small>
          <div className='social-icons'>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Footer;