import * as React from "react";
import { makeStyles, styled } from "@mui/material/styles";
import Stack from "@mui/material/Stack";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import "./index.css";

const ColorlibStepIconRoot = styled("div")<{
  ownerState: { completed?: boolean; active?: boolean };
}>(({ theme, ownerState }) => ({
  backgroundColor:
    theme.palette.mode === "dark" ? theme.palette.grey[700] : "#ccc",
  zIndex: 1,
  color: "#fff",
  width: 50,
  height: 50,
  display: "flex",
  borderRadius: "50%",
  justifyContent: "center",
  alignItems: "center",
  ...(ownerState.active && {
    backgroundImage:
      "linear-gradient( 136deg, rgb(242,113,33) 0%, rgb(233,64,87) 50%, rgb(138,35,135) 100%)",
    boxShadow: "0 4px 10px 0 rgba(0,0,0,.25)",
  }),
  ...(ownerState.completed && {
    backgroundImage:
      "linear-gradient( 136deg, rgb(242,113,33) 0%, rgb(233,64,87) 50%, rgb(138,35,135) 100%)",
  }),
}));

const steps = [
  { name: "2020年9月", des: "成立兴华万通供应链集团公司" },
  { name: "2020年12月", des: "第一轮业务并购、SOP制定" },
  { name: "2021年4月", des: "项目招商正式启动" },
  { name: "2022年2月", des: "飞翼 系统运营、VI品牌升级完毕" },
  { name: "2022年10月", des: "第二轮业务并购" },
];

export default function Dev() {
  return (
    <>
      <section className="section2">
        <div className="container">
          <ul className="caseStudyList">
            <li className="item item_l">
              <img src="../images/image-3.jpg" alt="" className="img" />
              <div className="bg-b"></div>
              <div className="bg-s">
                <img
                  src="../images/download2.png"
                  alt=""
                  className="bg-s-img"
                />
              </div>
              <div className="wrap">
                <div className="categories">
                  <h3>
                    智能业务系统 BI数据管理系统 WMS仓储管理系统 CRM客户管理系统
                    OA办公系统等物流信息智能管理系统投入研发、临沂点发全国项目立项
                  </h3>
                </div>
              </div>
            </li>
            <li className="item clearfix2 item_r">
              <img src="../images/image-1.jpg" alt="" className="img" />

              <div className="bg-b"></div>
              <div className="bg-s">
                <img
                  src="../images/download3.png"
                  alt=""
                  className="bg-s-img"
                />
              </div>
              <div className="wrap">
                <div className="categories">
                  <h3>
                    The secret behind BPMobile’s apps cracking the Chinese
                    market
                  </h3>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </section>
      <section className="section2">
        <Stack sx={{ width: "100%" }} spacing={4}>
          <Stepper alternativeLabel activeStep={6}>
            {steps.map((label, index) => (
              <Step key={index}>
                {/* StepIconComponent={ColorlibStepIcon} */}
                <StepLabel>
                  <div>
                    {label.name}
                    <div>{label.des}</div>
                  </div>
                </StepLabel>
              </Step>
            ))}
          </Stepper>
        </Stack>
      </section>
    </>
  );
}
