import {
  Alert,
  Box,
  Button,
  Card,
  Grid,
  Step,
  StepLabel,
  Stepper,
  TextField,
  Typography,
} from "@mui/material";
import OnlineBilling from "./components/onlineBilling";
import { LoadingButton } from "@mui/lab";
import { useState } from "react";
import { fetchWaybill } from "../home/services";

function parseAndMakePhoneClickable(note: any) {
  const phoneRegex = /(\d{11})/g;
  // const parsedNote = note.replace(phoneRegex, '<a href="tel:$1">$1</a>');
  const parsedNote = note.replace(phoneRegex, "");

  return (
    <div
      key={note}
      {...note[0]}
      dangerouslySetInnerHTML={{
        __html: parsedNote,
      }}
    />
  );
}

const Shipping = () => {
  const [waybillNumber, setWaybillNumber] = useState("");
  const [loading, setLoading] = useState(false);
  const [showStep, setShowStep] = useState(false);
  const [steps, setSteps] = useState<Log[]>([]);
  const [errorMessage, setErrorMessage] = useState("");
  const [addrFrom, setAddrFrom] = useState(false);
  const [addrTo, setAddrTo] = useState(false);

  const handleButtonClick = async () => {
    setLoading(true);
    await new Promise((resolve) => setTimeout(resolve, 1000));

    if (!waybillNumber) {
      setLoading(false);
      setShowStep(false);
      setErrorMessage("订单不能为空");
      return;
    }

    try {
      const response = await fetchWaybill(waybillNumber);
      if (response.success) {
        setShowStep(true);
        setErrorMessage("");
        setSteps(response.data.log);
        setAddrFrom(response.data.addrFrom);
        setAddrTo(response.data.addrTo);
        return;
      } else {
        setShowStep(false);
        setErrorMessage("运单号错误 请重新输入");
        return;
      }
    } catch (error) {
      setShowStep(false);
      setErrorMessage("网络异常");
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Box>
        {/* 运单查询 */}

        <Card
          sx={{
            width: "80%",
            margin: "auto",
            borderRadius: 5,
            marginTop: 3,
          }}
        >
          <Grid container spacing={2} padding={3} alignItems="center">
            <Grid item xs={12}>
              <Typography variant="h5">运单号查询</Typography>
            </Grid>
            <Grid item xs={10}>
              <TextField
                fullWidth
                id="to"
                placeholder="请输入运单号"
                value={waybillNumber}
                variant="outlined"
                onChange={(e) => setWaybillNumber(e.target.value)}
              />
            </Grid>
            <Grid item xs={2}>
              <LoadingButton
                variant="contained"
                onClick={handleButtonClick}
                loading={loading}
                fullWidth
                style={{ height: 50, background: "#ffbc07" }}
              >
                查询
              </LoadingButton>
            </Grid>
          </Grid>
        </Card>

        {errorMessage && (
          <Grid
            sx={{
              width: "80%",
              margin: "auto",
              borderRadius: 5,
              marginTop: 3,
            }}
          >
            <Alert variant="outlined" severity="error">
              {errorMessage}
            </Alert>
          </Grid>
        )}
        {showStep && (
          <Grid container display={"flex"} justifyContent={"center"}>
            <Grid item xs={9}>
              <Card
                sx={{
                  display: "flex",
                  justifyContent: "space-around",
                  borderRadius: 10,
                  marginTop: 3,
                  padding: 3,
                }}
              >
                <Box>
                  <h1>{addrFrom}</h1>
                </Box>
                <Box
                  display={"flex"}
                  justifyContent={"center"}
                  alignItems={"center"}
                >
                  <i className="fa-solid fa-right-long fa-2xl"></i>
                </Box>
                <Box>
                  <h1>{addrTo}</h1>
                </Box>
              </Card>
            </Grid>
            <Grid
              item
              xs={9}
              sx={{
                margin: 3,
              }}
            >
              <Stepper activeStep={0} orientation="horizontal">
                {steps.map((step, index) => {
                  const completed = index === 0;
                  const indexStep = steps.length - index;
                  return (
                    <Step key={index} completed={completed}>
                      <StepLabel icon={indexStep}>
                        <div>
                          <div>{step.actName}</div>
                          <div>{step.actTime}</div>
                          <div>{step.note[0]}</div>
                        </div>
                      </StepLabel>
                    </Step>
                  );
                })}
              </Stepper>
            </Grid>
          </Grid>
        )}

        <OnlineBilling />
      </Box>
    </>
  );
};

export default Shipping;
