import React from "react";
import {
  Grid,
  Button,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
} from "@mui/material";
import JoinAdvantagesTable from "./JoinAdvantagesTable";

const OpenRegion = () => {
  return (
    <>
      <Grid container spacing={2} padding={3} marginLeft={8}>
        <Grid item lg={1}>
          <FormControl variant="outlined" fullWidth>
            <InputLabel id="province-label">省份</InputLabel>
            <Select label="省份" labelId="province-label">
              <MenuItem value="province1">省份1</MenuItem>
              <MenuItem value="province2">省份2</MenuItem>
              {/* 添加更多省份 */}
            </Select>
          </FormControl>
        </Grid>
        <Grid item lg={1}>
          <FormControl variant="outlined" fullWidth>
            <InputLabel id="city-label">城市</InputLabel>
            <Select label="城市" labelId="city-label">
              <MenuItem value="city1">城市1</MenuItem>
              <MenuItem value="city2">城市2</MenuItem>
              {/* 添加更多城市 */}
            </Select>
          </FormControl>
        </Grid>
        <Grid item lg={1}>
          <FormControl variant="outlined" fullWidth>
            <InputLabel id="region-label">区域</InputLabel>
            <Select label="区域" labelId="region-label">
              <MenuItem value="region1">区域1</MenuItem>
              <MenuItem value="region2">区域2</MenuItem>
              {/* 添加更多区域 */}
            </Select>
          </FormControl>
        </Grid>
        <Grid item lg={1} display={"flex"} alignItems={"center"}>
          <Button variant="contained" color="primary" fullWidth>
            查找加盟
          </Button>
        </Grid>
      </Grid>

      <Grid container>
        <JoinAdvantagesTable />
      </Grid>
    </>
  );
};

export default OpenRegion;
