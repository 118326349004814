import React, { useState } from "react";
import { Button } from "../../../components/Button";

const HeroSection = () => {
  return (
    <div className="hero-container">
      {/* <video src="/videos/video-3.mp4" autoPlay loop muted playsInline /> */}
      <h1>为您提供放心的服务是我们的使命</h1>
      <p className="flag">
        Providing you with reassuring service is our mission
      </p>
      <div className="hero-btns">
        <Button
          className="btns"
          buttonStyle="btn--outline"
          buttonSize="btn--large"
          to="tel:4000009777"
        >
          联系我们
        </Button>
        <Button
          className="btns"
          buttonStyle="btn--primary"
          buttonSize="btn--large"
          to="tel:4000009777"
        >
          Contact Us
        </Button>
      </div>
    </div>
  );
};

export default HeroSection;
