import { Box } from "@mui/material";
import CustomizedAccordions from "./components/accordions";
import ComplaintForm from "./components/complaintForm";

const Feedback = () => {
  return (
    <>
      <Box
        sx={{
          display: "flex",
          margin: "auto",
          padding: 10,
          width: "80%",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <CustomizedAccordions />
        <ComplaintForm />
      </Box>
    </>
  );
};

export default Feedback;
