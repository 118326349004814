import React from "react";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";

const Culture = () => {
  const cultureSections = [
    {
      title: "价值观",
      description:
        "我们秉承着诚信、创新、卓越的价值观，致力于为客户提供卓越的物流服务。",
      icon: "emoji_events",
    },
    {
      title: "团队精神",
      description:
        "我们拥有充满激情和创造力的团队，共同努力实现物流行业的突破和创新。",
      icon: "group",
    },
    {
      title: "社会责任",
      description:
        "作为一家负责任的企业，我们致力于社会公益事业，回馈社会，关爱员工。",
      icon: "public",
    },
  ];

  return (
    <Container>
      <Typography variant="h3" align="center" mt={4} mb={2}>
        企业文化
      </Typography>
      <Grid container spacing={4}>
        {cultureSections.map((section, index) => (
          <Grid item key={index} xs={12} sm={6} md={4}>
            <Card
              sx={{
                minHeight: "250px",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                textAlign: "center",
              }}
            >
              <Stack spacing={2}>
                <span
                  className="material-icons"
                  style={{ fontSize: "3rem", color: "#1976D2" }}
                >
                  {section.icon}
                </span>
                <CardContent>
                  <Typography variant="h5">{section.title}</Typography>
                  <Typography variant="body2">{section.description}</Typography>
                </CardContent>
              </Stack>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Container>
  );
};

export default Culture;
