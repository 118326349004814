import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { StyledTableCell, StyledTableRow, createData } from "../tableStyle";

const ShanxiStation = () => {
  const rows = [
    createData("山西", "尖草坪杏花岭区卧虎山路柏华苑旁", "0351-3178-829"),
    createData("山西", "尖草坪杏花岭区卧虎山路柏华苑旁", "15735102226"),
  ];

  return (
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            <StyledTableCell>山西太原卸货网点</StyledTableCell>
            <StyledTableCell>地址</StyledTableCell>
            <StyledTableCell>电话</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <StyledTableRow key={row.phone}>
              <StyledTableCell component="th" scope="row">
                {row.name}
              </StyledTableCell>
              <StyledTableCell>{row.address}</StyledTableCell>
              <StyledTableCell>
                <a href="tel:${row.phone}"> {row.phone} </a>
              </StyledTableCell>
            </StyledTableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default ShanxiStation;
