import { Grid, Typography } from "@mui/material";

const Culture = () => {
  return (
    <>
      <Grid container spacing={5} padding={5}>
        <Grid item xs={12} display={"flex"}>
          <Grid item xs={6} display={"flex"} justifyContent={"center"}>
            <img src="/images/contemplative-reptile.jpg" />
          </Grid>
          <Grid item xs={6} textAlign={"center"} margin={"auto"}>
            <Typography variant="h4">企业理念</Typography>
            <Typography>开放合作 团结共赢</Typography>
          </Grid>
        </Grid>

        <Grid item xs={12} display={"flex"}>
          <Grid item xs={6} textAlign={"center"} margin={"auto"}>
            <Typography variant="h4">企业愿景</Typography>
            <Typography>兴华成通让每一个城市互联互通</Typography>
          </Grid>
          <Grid item xs={6} display={"flex"} justifyContent={"center"}>
            <img src="/images/contemplative-reptile.jpg" />
          </Grid>
        </Grid>

        <Grid item xs={12} display={"flex"}>
          <Grid item xs={6} display={"flex"} justifyContent={"center"}>
            <img src="/images/contemplative-reptile.jpg" />
          </Grid>
          <Grid item xs={6} textAlign={"center"} margin={"auto"}>
            <Typography variant="h4">企业使命</Typography>
            <Typography>服务千家万户共创和谐美好未来</Typography>
          </Grid>
        </Grid>

        <Grid item xs={12} display={"flex"}>
          <Grid item xs={6} textAlign={"center"} margin={"auto"}>
            <Typography variant="h4">企业价值观</Typography>
            <Typography>
              自强拼博 厚德仁爱 创新进取 义利兼顾 诚信担当
            </Typography>
          </Grid>
          <Grid item xs={6} display={"flex"} justifyContent={"center"}>
            <img src="/images/contemplative-reptile.jpg" />
          </Grid>
        </Grid>

        <Grid item xs={12} display={"flex"}>
          <Grid item xs={6} display={"flex"} justifyContent={"center"}>
            <img src="/images/contemplative-reptile.jpg" />
          </Grid>
          <Grid item xs={6} textAlign={"center"} margin={"auto"}>
            <Typography variant="h4">工作方针</Typography>
            <Typography>积极性 执行力 责任心</Typography>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default Culture;
