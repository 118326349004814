import { resolveObjectURL } from "buffer";

const apiUrl = "http://xhwt-api.56qqt.com/waybill/waybill-log";

async function fetchWaybill(waybill: string) {
  const url = `${apiUrl}?id=${encodeURIComponent(waybill)}`;
  const response = await fetch(url, {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json, text/plain, */*",
    },
  });

  if (!response.ok) {
    throw new Error(`Network response was not ok, status: ${response.status}`);
  }
  return response.json();
}

async function fetchStation() {
  const url = "http://xhwt-api.56qqt.com/setting/node/getSiteList";

  // const url = "https://localhost:6001/setting/node/getSiteList";

  const response = await fetch(url, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json, text/plain, */*",
    },
  });

  return response.json();
}

export { fetchWaybill, fetchStation };
