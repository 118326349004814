import React from "react";
import { Grid, Typography } from "@mui/material";

const JoinAdvantages: React.FC = () => {
  return (
    <Grid
      container
      display={"flex"}
      justifyContent={"center"}
      spacing={3}
      padding={5}
    >
      {/* 企业优势 */}
      <Grid item xs={10} lg={10}>
        <Typography variant="h4" sx={{ marginBottom: 2 }}>
          加盟优势
        </Typography>

        <Typography>
          <strong>1. 企业优势</strong>
        </Typography>
        <Typography>
          壹米滴答提供高水准门到门快运服务。采用标准定价、标准操作流程，各环节均以较快速度进行发运、中转、派送，并对客户进行相对标准承诺的快运产品。
        </Typography>

        <Typography>
          <strong>2. 行业优势</strong>
        </Typography>
        <Typography>
          1.物流行业经过短时间内的快速崛起，其主要原因为国家政策的扶持和支持；中国电子商务市场，消费者行为习惯的变革推动了整个市场快速发展；同时，快运独特的行业状况和商业模式，引领公司、合作伙伴、顾客认知共同前进。最终实现顾客价值和销售目标。从而推动行业的快速发展和变革!
        </Typography>
        <Typography>
          2.然而当前行业前10名的整体份额小于5%，相对于美国行业前10名占行业60%，这个行业还处在早期阶段。
        </Typography>
        <Typography>
          3. 这是一个变革的时代。我们应当庆幸，庆幸的是身处变革时代谁都有机会。
        </Typography>
      </Grid>

      {/* 加盟条件 */}
      <Grid item xs={10} lg={10}>
        <Typography variant="h4" sx={{ marginBottom: 2 }}>
          加盟条件
        </Typography>

        <Typography>1. 认同壹米滴答发展的理念，有相同的发展愿景；</Typography>
        <Typography>2. 具备揽货能力及基本配送能力；</Typography>
        <Typography>
          3. 统一使用信息系统、资金账户、统一网点经营、统一品牌形象；
        </Typography>
        <Typography>
          4. 接受壹米滴答业务量考核，严格执行运营标准，并接受管控考核；
        </Typography>
        <Typography>5. 缴纳一定的押金、系统使用费等费用</Typography>
      </Grid>

      {/* 加盟政策扶持 */}
      <Grid item xs={10} lg={10}>
        <Typography variant="h4" sx={{ marginBottom: 2 }}>
          加盟政策扶持
        </Typography>

        <Typography>
          <strong>1. 一对一帮扶</strong>
        </Typography>
        <Typography>
          开业后，由片区网管/直营门店经理对新开网点进行现场指导操作，并针对出现问题及时协助解决。
        </Typography>
        <Typography>
          <strong>2. 罚款减免</strong>
        </Typography>
        <Typography>
          新开加盟网点享有半个月罚款减免期，即从新开网点第一次开单/到达之日起开始计算顺延15天做为罚款减免期，遗失、破损、短少、违禁品除外。
        </Typography>
        <Typography>
          <strong>3. 系统支持</strong>
        </Typography>
        <Typography>加盟网点享有公司系统的使用权以及IT支持等。</Typography>
      </Grid>

      {/* 加盟流程 */}
      <Grid item xs={10} lg={10}>
        <Typography variant="h4" sx={{ marginBottom: 2 }}>
          加盟流程
        </Typography>

        <Typography>
          提交加盟申请 {">"} 加盟人资质筛选与审核 {">"} 缴纳费用 {">"}{" "}
          签订加盟协议
          {">"} 网点选址、装修验收 {">"} 办理营业执照 {">"} 培训学习 {">"}
          提交新开点工作流 {">"} 开业前准备 {">"} 开业
        </Typography>
      </Grid>
    </Grid>
  );
};

export default JoinAdvantages;
