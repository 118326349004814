import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { StyledTableCell, StyledTableRow, createData } from "../tableStyle";

const JiangsuStation = () => {
  const rows = [
    createData("扬州", "广陵区汤汪路31号", "18051057789"),
    createData("仪征", "仪征市站前路1号智慧物流园", "15052517268"),
    createData("兴化", "昭阳工业园第1区振兴路5号", "0523-85294444"),
    createData("宝应", "宝应县淮江农贸市场院内", "0514-88982444"),
    createData("高邮", "秦邮路8号双兔米厂院内", "0514-84061080"),
    createData("泰州", "海陵区兴泰北路10号大院永盛物流", "0523-86651234"),
    createData("泰兴", "泰兴市通江路万通物流中心A8号", "0523-89777528"),
    createData(
      "靖江",
      "新渔婆菜市场向北红绿灯向西200米常太路6号",
      "18901432779"
    ),
    createData("江都", "长江东路恒亿物流园D区永盛物流", "0514-86975602"),
    createData(
      "盐城",
      "新业东路8号（运达物流园A区10-15号）新淮盐货运",
      "0515-89908518"
    ),
    createData("东台", "东台市明星家具城东侧（青松物流）", "0515-85853008"),
    createData("大丰", "大丰南翔东路2号老瓜果市场内", "0515-83398588"),
    createData("南通", "南通四季食品城凤凰物流园内永盛物流", "0513-89089107"),
    createData("海安", "海安县黄河路弘润达钢材市场726号", "15366379425"),
    createData("如皋", "如皋市苏浙大市场北门菜鸟驿站", "15851374709"),
    createData("启东", "牡丹江西路233号", "13338099111"),
    createData("海门", "海门市人民西路1279号正扬物流园内", "18036441116"),
    createData("淮安", "上海西路67号方达运输公司院内", "0517-83188088"),
    createData("博德市场", "清江浦区深圳东路卓伟科技园内", "18014153122"),
    createData(
      "连水",
      "S327省道与北京路交汇南200米路西永盛物流",
      "15366606664"
    ),
    createData(
      "宿迁",
      "宿迁市宿城区荣业物流园C2幢21号门永盛物流",
      "18305248261"
    ),
    createData(
      "射阳",
      "大车站西侧羽绒城西大门向南88米（永盛物流）",
      "0515-82266128"
    ),
    createData("洪泽", "胡庄路10号江苏嘉柏俐涂料有限公司院内", "17314157706"),
    createData("金湖", "金湖县东阳南路与清河东路交汇永盛物流", "0517-86807900"),
    createData("徐州", "淮海食品城1号停车场8-9号", "0516-82160878"),
    createData(
      "睢宁",
      "东环岛汽车站南樱花路与新城路交汇东50米路南",
      "17802652007"
    ),
    createData("沭阳", "百盟物流园零担区3-25/27/29/33号", "0527-89994689"),
  ];

  return (
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            <StyledTableCell>江苏专线卸货网点</StyledTableCell>
            <StyledTableCell>地址</StyledTableCell>
            <StyledTableCell>电话</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row, index) => (
            <StyledTableRow key={index}>
              <StyledTableCell component="th" scope="row">
                {row.name}
              </StyledTableCell>
              <StyledTableCell>{row.address}</StyledTableCell>
              <StyledTableCell>
                <a href="tel:${row.phone}"> {row.phone} </a>
              </StyledTableCell>
            </StyledTableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default JiangsuStation;
