import HeroSection from "./components/HeroSection";
import StaticMap from "./components/Map";
import CardItem from "./components/CardItem";
import "../home/index.css";
import LineForm from "./components/LineForm";
import WaybillForm from "./components/WaybillForm";

const Home = () => {
  return (
    <>
      <HeroSection />
      <div className="query-container">
        <LineForm />
        <WaybillForm />
      </div>
      <div className="cards">
        <h1>点点直达 · 互联互通</h1>
        <div className="cards__container">
          <div className="cards__wrapper">
            <ul className="cards__items">
              <CardItem
                src="images/img-3.jpg"
                text="兴华成通让每一个城市互联互通"
                label="企业愿景"
                path="/"
              />
              <CardItem
                src="images/img-4.jpg"
                text="服务千家万户共创和谐美好未来"
                label="企业使命"
                path="/"
              />
              <CardItem
                src="images/img-5.jpg"
                text="自强拼博 厚德仁爱 创新进取 义利兼顾 诚信担当"
                label="价值观"
                path="/"
              />
              <CardItem
                src="images/img-6.jpg"
                text="积极性 执行力 责任心"
                label="九字方针"
                path="/"
              />
            </ul>
          </div>
        </div>
      </div>
      <div className="about">
        <h1>集团简介</h1>
        <div className="about-container">
          <div>
            <img src="images/img-7.jpg" className="img-container" />
          </div>
          <div className="txt-container">
            <p>山东兴华万通供应链集团有限公司成立于2020年9月2日</p>
            <p>
              截止目前公司在搭建全国物流平台项目上已投资2千万元，计划总投资金额2亿元。
            </p>
            <p>
              兴华万通将打造临沂至全国互联互通的智慧物流平台，建设仓配一体化枢纽中心，赋能传统专线物流，改变传统专线物流的运营模式，实现智能化、标准化、自动化的现代科技型综合物流企业，塑造临沂区域优质品牌
            </p>

            <p>
              现公司覆盖河南、江苏、山东、安徽、河北、湖北、湖南、贵州、云南、山西、新疆、西藏、青海、甘肃、宁夏的15个省的运输线路，拥有148个网点，年吞吐量122.5万余吨，年营业额约3.87亿元，在职企业员工1000余人,未来将再带动就业数万人。
            </p>
          </div>
        </div>
      </div>
      <StaticMap />
    </>
  );
};

export default Home;
